exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-callback-js": () => import("./../../../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mod-quiz-js": () => import("./../../../src/pages/mod-quiz.js" /* webpackChunkName: "component---src-pages-mod-quiz-js" */),
  "component---src-pages-month-success-js": () => import("./../../../src/pages/month-success.js" /* webpackChunkName: "component---src-pages-month-success-js" */),
  "component---src-pages-myame-js": () => import("./../../../src/pages/myame.js" /* webpackChunkName: "component---src-pages-myame-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-year-success-js": () => import("./../../../src/pages/year-success.js" /* webpackChunkName: "component---src-pages-year-success-js" */),
  "component---src-templates-course-view-js": () => import("./../../../src/templates/course-view.js" /* webpackChunkName: "component---src-templates-course-view-js" */)
}

